import React from "react";
import "./Footer.css";

function Footer() {
	return (
		<footer className="footer">
			<a
				href="home"
				className="arrow-up-container"
				onClick={(e) => {
					e.preventDefault();
					let home = document.getElementById("home");
					home && home.scrollIntoView({ behavior: "smooth" });
				}}
			>
				<svg
					width="106"
					height="120"
					viewBox="0 0 106 120"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g id="triangles">
						<g id="lightGroup">
							<path
								id="light1"
								opacity="0.7"
								d="M50.7679 48C51.5377 46.6667 53.4623 46.6667 54.2321 48L74.5836 83.25C75.3534 84.5833 74.3912 86.25 72.8516 86.25H32.1484C30.6088 86.25 29.6466 84.5833 30.4164 83.25L50.7679 48Z"
							/>
						</g>
						<g id="darkGroup">
							<path
								id="dark1"
								opacity="0.7"
								d="M50.7679 30C51.5377 28.6667 53.4623 28.6667 54.2321 30L74.5836 65.25C75.3534 66.5833 74.3912 68.25 72.8516 68.25H32.1484C30.6088 68.25 29.6466 66.5833 30.4164 65.25L50.7679 30Z"
							/>
							<path
								id="dark2"
								opacity="0.7"
								d="M50.7679 48C51.5377 46.6667 53.4623 46.6667 54.2321 48L74.5836 83.25C75.3534 84.5833 74.3912 86.25 72.8516 86.25H32.1484C30.6088 86.25 29.6466 84.5833 30.4164 83.25L50.7679 48Z"
							/>
						</g>
					</g>
				</svg>
			</a>

			<div className="social-icons">
				<svg
					id="social-icons__github"
					height="35"
					aria-hidden="true"
					viewBox="0 0 16 16"
					version="1.1"
					width="35"
					fill="white"
					data-view-component="true"
					onClick={(e) => {
						e.preventDefault();
						window.open("https://github.com/CosmicVoiid");
					}}
				>
					<path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
				</svg>
				<a href="mailto: saurav.sah01@gmail.com">
					<svg
						id="social-icons__email"
						width="35"
						height="35"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle id="email-circle" cx="15" cy="15" r="15" />
						<path
							id="email-part1"
							d="M14.7045 16.3775C14.8686 16.5384 15.1314 16.5384 15.2955 16.3775L23.5857 8.25277C23.8505 7.9932 23.6722 7.53695 23.3014 7.53516C23.2985 7.53516 23.2956 7.53516 23.2927 7.53516H6.70732C6.70442 7.53516 6.70157 7.53516 6.69864 7.53516C6.32786 7.53699 6.1495 7.99324 6.41435 8.25277L14.7045 16.3775Z"
						/>
						<path
							id="email-part2"
							d="M25 9.2291C24.9972 8.8591 24.5463 8.67718 24.2821 8.9362L15.3414 17.6986C15.1518 17.8845 14.8482 17.8845 14.6586 17.6986L5.71797 8.9362C5.45371 8.67722 5.00285 8.85913 5.00008 9.2291C5 9.23355 5 9.23796 5 9.24242V20.7575C5 21.7005 5.76438 22.4648 6.70731 22.4648H23.2927C24.2356 22.4648 25 21.7005 25 20.7575V9.24242C25 9.23796 25 9.23355 25 9.2291Z"
						/>
					</svg>
				</a>
			</div>
			<div className="trademark">SAUARV SAH &copy;2022</div>
		</footer>
	);
}

export default Footer;
